import type { PageComponent } from '@/core/features/a-dynamic-page/dynamic-page-pacts/dynamic-page-type';
import type { PageComponentPropsWithComponent } from '@/features/a-dynamic-page/components/page-component-switch';

import { getComponentId } from '@/core/features/a-component/services/component-id-service';
import getPageDataComponentByName from '@/features/a-dynamic-page/components/page-data-component-registry';

export default function PageDataComponentsSwitch(props: { components: PageComponent[] }) {
    const dataComponents = props.components.flatMap((component, index) => {
        const dataComponentConfig = getPageDataComponentByName(component['@type']);
        if (!dataComponentConfig) {
            return [];
        }

        const dataComponentProps: PageComponentPropsWithComponent<typeof component> = {
            attributes: component.attributes,
            component: component,
            componentId: getComponentId(component['@id']),
            componentIndex: 0, // not needed in data components
            componentIri: component['@id'],
            componentType: component['@type'],
            isPageLoading: false,
            meta: component.meta,
            pageComponentIndex: 0, // not needed in data components
            pageComponentNames: [], // not needed in data components
            ...component.attributes,
        };

        return {
            dataComponentConfig,
            dataComponentProps,
            key: `${dataComponentProps.componentId}-${index}`,
        };
    });

    return (
        <>
            {dataComponents.map(({ dataComponentConfig, dataComponentProps, key }) => {
                const Component = dataComponentConfig.render;
                if (!Component) {
                    return null;
                }

                return (
                    <Component
                        key={key}
                        {...dataComponentProps}
                    />
                );
            })}
        </>
    );
}
